import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-321e9070"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
import { computed, watch } from 'vue';
import { ref, reactive } from 'vue';

// 定义父组件传递的属性

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true
    },
    disable_date_array: {
      type: Array,
      // 2024-08-12
      // eslint-disable-next-line vue/require-valid-default-prop  
      default: []
    },
    end_date: {
      type: String,
      default: ''
    }
  },
  emits: ['update:show', 'conformDate'],
  setup(__props, {
    emit
  }) {
    const props = __props;

    // 定义emits

    const datePickerRef = ref(null);

    // picker依赖show状态
    const showPicker = computed({
      get() {
        return props.show;
      },
      set(value) {
        emit('update:show', value);
      }
    });
    const currentDate = ref([]);
    const current_date = new Date();
    //最小日期是本周周四-所以需要提前两天
    const minDate = new Date(current_date);
    minDate.setDate(current_date.getDate() + 2);
    //最大日期30天
    const currentDateAdd30 = new Date(current_date);
    currentDateAdd30.setDate(current_date.getDate() + 30);

    // 以项目结束时间和当前时间30天最小日期为结束日期
    const maxDate = ref('');
    watch(() => props.end_date, newValue => {
      var endDate = new Date(newValue.split(' ')[0]);
      maxDate.value = endDate < currentDateAdd30 ? endDate : currentDateAdd30;
    });
    const currentTime = ref(['']);
    const timeColumnsType = ['hour'];
    const filter = (type, options) => {
      if (type === 'day') {
        //只显示允许推送天-周六日
        return options.filter(option => {
          // 获取当前选择的年份和月份
          const year = currentDate.value[0]; // 或从你的日期选择器中获取年份
          const month = currentDate.value[1] - 1; // 0 表示一月，1 表示二月，以此类推

          // 手动拼接成完整日期对象
          const result_date = new Date(year, month, Number(option.value)); // 构造新的 Date 对象，月份是 0 基的

          if (props.disable_date_array.includes(`${year}-${month}-${option.value}`)) {
            return false;
          }

          // 如果不是周一到周四，则本周周六日不可预约
          const day = current_date.getDay(); // 今天星期几 1-6星期一至六 0 星期日
          if (day > 4) {
            // 计算两个日期的时间戳差值，并转换为天数
            const differenceInMilliseconds = result_date - current_date;
            const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
            //向下取整，得到完整的天数
            if (Math.floor(differenceInDays) < 2) {
              return false;
            }
          }

          // 检查 date 是否为有效的 Date 对象
          if (isNaN(result_date.getTime())) {
            return false;
          }

          // 获取星期几
          const result_day = result_date.getDay();

          // 判断是否为周六或周日
          return result_day == 6 || result_day == 0;
        });
      } else if (type === 'hour') {
        //只显示允许推送时间
        return options.filter(option => [10, 11, 12, 15, 17, 19, 20].includes(Number(option.value)));
      }
      return options;
    };
    const formatter = (type, option) => {
      if (type === 'year') {
        option.text += '年';
      } else if (type === 'month') {
        option.text += '月';
      } else if (type === 'day') {
        option.text += '日';
      } else if (type === 'hour') {
        option.text += '时';
      }
      return option;
    };
    const themeVars = reactive({
      popupRoundRadius: '12px',
      pickerToolbarHeight: '48px',
      pickerActionFontSize: '14px',
      pickerConfirmActionColor: '#00C4A1',
      tabFontSize: '15px',
      tabsBottomBarColor: '#000000',
      tabsBottomBarWidth: '24px',
      pickerOptionFontSize: '18px'
    });
    function onConfirm() {
      showPicker.value = false;
      console.log('onConfirm----', currentDate.value.join('-'), currentTime.value.join(':'));
      emit('conformDate', {
        'date': currentDate.value.join('-'),
        'hour': currentTime.value.join(':')
      });
    }
    return (_ctx, _cache) => {
      const _component_van_date_picker = _resolveComponent("van-date-picker");
      const _component_van_time_picker = _resolveComponent("van-time-picker");
      const _component_van_picker_group = _resolveComponent("van-picker-group");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_config_provider = _resolveComponent("van-config-provider");
      return _openBlock(), _createBlock(_component_van_config_provider, {
        "theme-vars": _unref(themeVars)
      }, {
        default: _withCtx(() => [_createVNode(_component_van_popup, {
          show: _unref(showPicker),
          "onUpdate:show": _cache[3] || (_cache[3] = $event => _isRef(showPicker) ? showPicker.value = $event : null),
          round: "",
          position: "bottom"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_picker_group, {
            title: "选择日期时间",
            tabs: ['日期', '时间'],
            "next-step-text": "确定",
            "confirm-button-text": "确定",
            onConfirm: onConfirm,
            onCancel: _cache[2] || (_cache[2] = $event => showPicker.value = false)
          }, {
            default: _withCtx(() => [_createVNode(_component_van_date_picker, {
              ref_key: "datePickerRef",
              ref: datePickerRef,
              modelValue: currentDate.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => currentDate.value = $event),
              formatter: formatter,
              "min-date": _unref(minDate),
              filter: filter,
              "option-height": "48",
              "max-date": maxDate.value
            }, null, 8, ["modelValue", "min-date", "max-date"]), _createVNode(_component_van_time_picker, {
              modelValue: currentTime.value,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => currentTime.value = $event),
              formatter: formatter,
              "option-height": "48",
              filter: filter,
              "columns-type": timeColumnsType
            }, null, 8, ["modelValue"])]),
            _: 1
          })])]),
          _: 1
        }, 8, ["show"])]),
        _: 1
      }, 8, ["theme-vars"]);
    };
  }
};